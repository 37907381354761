import React from "react";
import { motion } from "framer-motion";
import Layout from "../components/layout";
import { PartnersHeader } from "../images/partners-header";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";

const Partners = ({ location }) => {
	return (
		<>
			<SEO title={"Partners"} location={location} />
			<Layout lang={"en"}>
				<div className="w-11/12 xl:w-9/12 mx-auto bg-[#FAFBFC] lg:grid grid-cols-3 rounded-3xl px-8 lg:pl-16 pt-12">
					<div className=" flex flex-col justify-center col-span-2">
						<motion.h2
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { opacity: 0 },
							}}
							className={
								"text-3xl md:text-4xl xl:text-5xl text-primary font-bold mb-9"
							}
						>
							Our Partners
						</motion.h2>
						<motion.p
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							transition={{ duration: 1 }}
							variants={{
								visible: { y: 0, opacity: 1 },
								hidden: { opacity: 0 },
							}}
							className={"w-11/12 xl:text-xl text-[#323B4B] mb-16"}
						>
							Buducloud is partnered by a diverse group of global enterprises
							that share our vision and decided to embark on this journey with
							us.
						</motion.p>
					</div>
					<div className="self-end w-full h-full">
						<PartnersHeader className=" w-full h-full" />
					</div>
				</div>
				<section className="w-11/12 xl:w-9/12 mx-auto py-12 text-center mb-12">
					<h2 className="text-primary font-bold text-3xl mb-12">Enterprises</h2>
					<div className="grid grid-cols-2  lg:grid-cols-5 gap-8 lg:gap-14 place-items-center mb-10">
						<div className=" aspect-square">
							<StaticImage
								className="rounded-2xl aspect-auto"
								quality={100}
								placeholder="blurred"
								layout="constrained"
								src="../images/Enterprise/Logo Partner01.png"
							/>
						</div>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner02.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner03.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner04.png"
						/>
						<StaticImage
							src="../images/Enterprise/Logo Partner05.png"
							className="rounded-2xl aspect-auto col-span-2 lg:col-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
						/>
					</div>
					<div className="grid grid-cols-2 lg:grid-cols-4 place-items-center gap-8 lg:gap-14 place">
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner06.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner07.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner08.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Enterprise/Logo Partner09.png"
						/>
					</div>
				</section>
				<section className="w-11/12 xl:w-9/12 mx-auto py-12 text-center">
					<h2 className="text-primary font-bold text-3xl mb-12">SMEs</h2>
					<div className="grid grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-14 place-items-center mb-10">
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/Logo Partner01-1.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/Logo Partner01.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/Logo Partner02.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/Logo Partner03.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto col-span-2 lg:col-auto"
							quality={100}
							placeholder="blurred"
							src="../images/SMes/Logo Partner04.png"
						/>
					</div>
					<div className="grid grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-14">
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/row 2/Logo Partner01.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/row 2/Logo Partner02.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/row 2/Logo Partner03.png"
						/>
						<StaticImage
							className="rounded-2xl "
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/SMes/row 2/Logo Partner04.png"
						/>
					</div>
				</section>
				<section className="w-11/12 xl:w-9/12 mx-auto py-12 text-center">
					<h2 className="text-primary font-bold text-3xl mb-12">
						Strategic Partners
					</h2>
					<div className="grid grid-cols-2 lg:grid-cols-5 place-items-center gap-8 lg:gap-14 mb-10">
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Strategic Partners/Logo Partner01.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Strategic Partners/Logo Partner02.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Strategic Partners/Logo Partner03.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Strategic Partners/Logo Partner04.png"
						/>
						<StaticImage
							className="rounded-2xl aspect-auto col-span-2 lg:col-auto"
							quality={100}
							placeholder="blurred"
							layout="constrained"
							src="../images/Strategic Partners/Logo Partner05.png"
						/>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default Partners;
